import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { Link } from "gatsby"
import { animateScroll as scroll } from "react-scroll"
import ReactVivus from "react-vivus"

import Gradients from "src/components/gradients"
import TopMenu from "src/components/top_menu"
import Footer from "src/components/footer"
import urls from "src/content/urls"

import fpStyles from "src/css/fp-new.module.css"

import oscar from "src/images/oscar better3 building.png"
import chata from "src/images/pages/frontpage/chata.png"

import fingerprint from "src/images/icons2/fingerprint.svg"
import tshirt from "src/images/icons2/tshirt.svg"
import ulotki from "src/images/icons2/paper.svg"
import store from "src/images/icons2/store.svg"
import website from "src/images/icons2/website.svg"
import marketing from "src/images/icons2/marketing.svg"
import socialmedia from "src/images/icons2/socialmedia.svg"
import movies from "src/images/icons2/video.svg"
import wrapping from "src/images/icons2/wrapping.svg"
import printers from "src/images/icons2/printing.svg"
import it from "src/images/icons2/it.svg"

import iia_left from "src/images/idiot_info_arrow/left.svg"
import iia_right from "src/images/idiot_info_arrow/right.svg"

import blueLogo from "src/images/logo-blueBorder.png"


class NewIndex extends React.Component {
  constructor(props) {
    super(props)

    this.timelineObj = React.createRef()
    this.idiotInfo = React.createRef()

    this.leftHiderObj = React.createRef()
    this.leftHiderIconObj = React.createRef()
    this.rightHiderObj = React.createRef()
    this.rightHiderIconObj = React.createRef()

    this.timelineElemsObj = {
      line01: React.createRef(),
      line02: React.createRef(),
      line03: React.createRef(),
      line04: React.createRef(),
      line05: React.createRef(),
      line06: React.createRef(),
      chata: React.createRef(),
      oscar: React.createRef(),
      oscarImg: React.createRef(),
      stage01: React.createRef(),
      stage02: React.createRef(),
      stage03: React.createRef(),
      stage04: React.createRef(),
      stage05: React.createRef(),
      titles01: React.createRef(),
      titles02: React.createRef(),
      titles03: React.createRef(),
      titles04: React.createRef(),
      titles05: React.createRef(),
    }

    this.state = {
      maxTimelineOffset: 0,
      focusedStage: undefined,
    }

    this.scrollLeft = this.scrollLeft.bind(this)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.windowW.windowW !== nextProps.windowW.windowW || this.state.maxTimelineOffset === 0) {
      if (this.timelineObj.current != null) {
        this.setState({
          ...this.state,
          maxTimelineOffset: this.timelineObj.current.clientWidth - nextProps.windowW.windowW,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener("load", (ev) => {
      //alert("loaded")
      // alert("sss")
      // confirm("tak?")
    })
  }

  scrollLeft() {
    // scroll.scrollMore(this.state.maxTimelineOffset, {
    //   smooth: true,
    //   duration: 500,
    // })
  }

  focusStage(stage) {
    this.setState({ ...this.state, focusedStage: stage })
    //setTimeout(() => console.log(this.state), 100)
  }

  unfocusStage() {
    this.setState({ ...this.state, focusedStage: undefined })
    //setTimeout(() => console.log(this.state), 100)
  }

  render() {
    //console.log("props render:", this.props, "state render", this.state)
    let timelinePos = this.props.scrollPos.scrollPos ? this.props.scrollPos.scrollPos : 0

    let timelineStyles = {}
    let fpContentStyles = {}
    let fpWrapperStyles = {}
    let fpEndContentStyles = {}
    let fpFooterStyles = { position: "absolute" }

    if (this.timelineObj.current != null) {
      if (timelinePos >= this.state.maxTimelineOffset) {
        fpContentStyles.position = "absolute"
        fpContentStyles.top = this.state.maxTimelineOffset
      } else {
        fpContentStyles.position = "fixed"
      }



      let offset = timelinePos > this.state.maxTimelineOffset ? this.state.maxTimelineOffset : timelinePos
      timelineStyles.transform = "translateX(-" + offset + "px)"

      fpEndContentStyles.position = "absolute"
      fpEndContentStyles.top = this.props.windowH.windowH + this.state.maxTimelineOffset

      fpFooterStyles.top = this.props.windowH.windowH + this.state.maxTimelineOffset
    }

    let oscar_offset = 0
    if (this.timelineElemsObj.oscarImg.current != null) {
      oscar_offset = this.timelineElemsObj.oscarImg.current.clientHeight * 0.40 - 30
    }

    if (this.state.focusedStage !== undefined) {
      let focusedStage = this.state.focusedStage
      setTimeout(() => {
        if (this.state.focusedStage === focusedStage)
          Object.keys(this.timelineElemsObj).map((key) => {
            let elem = this.timelineElemsObj[key]
            if (elem.current != null) {
              elem = elem.current
              if (elem.classList.contains(fpStyles.normalStage)) {
                if (elem.classList.contains(fpStyles.stage) || elem.classList.contains(fpStyles.titles)) {
                  if (elem.id === "stage0" + this.state.focusedStage || elem.id === "titles0" + this.state.focusedStage)
                    return
                }
                elem.classList.remove(fpStyles.normalStage)
                elem.classList.add(fpStyles.notFocusedStage)
              }
            }
          })
      }, 500)
    } else {
      Object.keys(this.timelineElemsObj).map((key) => {
        let elem = this.timelineElemsObj[key]
        if (elem.current != null) {
          elem = elem.current
          if (elem.classList.contains(fpStyles.notFocusedStage)) {
            elem.classList.remove(fpStyles.notFocusedStage)
            elem.classList.add(fpStyles.normalStage)
          }
        }
      })
    }

    return (
      <div className={fpStyles.front_page_wrapper} style={fpWrapperStyles}>
        <div className={fpStyles.front_page}>

          <Gradients activeGradient={this.state.focusedStage}/>

          <div className={fpStyles.fp_content} style={fpContentStyles}>

            <TopMenu/>

            <div className={fpStyles.fp_logo}>
              <img src={blueLogo}/>
            </div>

            <div className={fpStyles.timeline2_wrapper} ref={this.timelineObj}>
              <div className={fpStyles.timeline2_box}>
                <div className={fpStyles.timeline2} style={timelineStyles} onClick={this.scrollLeft}>


                  <div className={[fpStyles.stage_home, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.chata}>
                    <img src={chata}/>
                  </div>

                  <div className={[fpStyles.line, fpStyles.line01, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.line01}/>

                  <div className={[fpStyles.line, fpStyles.line02, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.line02}/>

                  <div className={[fpStyles.line, fpStyles.line03, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.line03}/>

                  <div className={[fpStyles.line, fpStyles.line04, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.line04}/>

                  <div className={[fpStyles.line, fpStyles.line05, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.line05}/>

                  <div className={[fpStyles.line, fpStyles.line06, fpStyles.normalStage].join(" ")}
                       ref={this.timelineElemsObj.line06}/>


                  <div className={[fpStyles.stage, fpStyles.stage01, fpStyles.normalStage].join(" ")} id="stage01"
                       ref={this.timelineElemsObj.stage01}>
                    <div className={fpStyles.stage_content} onMouseEnter={() => this.focusStage(1)}
                         onMouseLeave={() => this.unfocusStage()}>
                      <div className={fpStyles.stage_icons}>
                        <Link to={urls.pl.branding}>
                          <ReactVivus
                            id="fingerprint-icon"
                            option={{
                              file: fingerprint,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.fingerprint_icon}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>


                  <div className={[fpStyles.stage, fpStyles.stage02, fpStyles.normalStage].join(" ")} id="stage02"
                       ref={this.timelineElemsObj.stage02}>
                    <div className={fpStyles.stage_content} onMouseEnter={() => this.focusStage(2)}
                         onMouseLeave={() => this.unfocusStage()}>
                      <div className={fpStyles.stage_icons}>
                        <Link to={urls.pl.gadgets}>
                          <ReactVivus
                            id="tshirt-icon"
                            option={{
                              file: tshirt,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.tshirt_icon}
                          />
                        </Link>
                        <Link to={urls.pl.papers}>
                          <ReactVivus
                            id="papers-icon"
                            option={{
                              file: ulotki,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.papers_icon}
                          />
                        </Link>
                        <Link to={urls.pl.outside}>
                          <ReactVivus
                            id="store-icon"
                            option={{
                              file: store,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.store_icon}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={[fpStyles.stage, fpStyles.stage03, fpStyles.normalStage].join(" ")} id="stage03"
                       ref={this.timelineElemsObj.stage03}>
                    <div className={fpStyles.stage_content} onMouseEnter={() => this.focusStage(3)}
                         onMouseLeave={() => this.unfocusStage()}>
                      <div className={fpStyles.stage_icons}>
                        <Link to={urls.pl.marketing}>
                          <ReactVivus
                            id="marketing-icon"
                            option={{
                              file: marketing,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.marketing_icon}
                          />
                        </Link>
                        <Link to={urls.pl.websites}>
                          <ReactVivus
                            id="website-icon"
                            option={{
                              file: website,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.website_icon}
                          />
                        </Link>
                        <Link to={urls.pl.socialmedia}>
                          <ReactVivus
                            id="socialmedia-icon"
                            option={{
                              file: socialmedia,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.socialmedia_icon}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={[fpStyles.stage, fpStyles.stage04, fpStyles.normalStage].join(" ")} id="stage04"
                       ref={this.timelineElemsObj.stage04}>
                    <div className={fpStyles.stage_content} onMouseEnter={() => this.focusStage(4)}
                         onMouseLeave={() => this.unfocusStage()}>
                      <div className={fpStyles.stage_icons}>
                        <Link to={urls.pl.wideo}>
                          <ReactVivus
                            id="movies-icon"
                            option={{
                              file: movies,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.movies_icon}
                          />
                        </Link>
                        <Link to={urls.pl.wrapping}>
                          <ReactVivus
                            id="wrapping-icon"
                            option={{
                              file: wrapping,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.wrapping_icon}
                          />
                        </Link>
                        <Link to={urls.pl.printing}>
                          <ReactVivus
                            id="printers-icon"
                            option={{
                              file: printers,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.printers_icon}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={[fpStyles.stage, fpStyles.stage05, fpStyles.normalStage].join(" ")} id="stage05"
                       ref={this.timelineElemsObj.stage05}>
                    <div className={fpStyles.stage_content} onMouseEnter={() => this.focusStage(5)}
                         onMouseLeave={() => this.unfocusStage()}>
                      <div className={fpStyles.stage_icons}>
                        <Link to={urls.pl.it}>
                          <ReactVivus
                            id="it-icon"
                            option={{
                              file: it,
                              type: "delayed",
                              animTimingFunction: "EASE",
                              duration: 100,
                            }}
                            className={fpStyles.it_icon}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>


                  <div className={[fpStyles.stage_oscar, fpStyles.normalStage].join(" ")} id="oscar-building"
                       ref={this.timelineElemsObj.oscar}>
                    <img src={oscar} ref={this.timelineElemsObj.oscarImg} style={{marginBottom: "-"+oscar_offset+"px"}}/>
                  </div>


                  <div className={[fpStyles.titles, fpStyles.titles01, fpStyles.normalStage].join(" ")} id="titles01"
                       ref={this.timelineElemsObj.titles01}>
                    <Link to={urls.pl.branding}>
                      <div>STWORZENIE<br/>MARKI</div>
                    </Link>
                  </div>

                  <div className={[fpStyles.titles, fpStyles.titles02, fpStyles.normalStage].join(" ")} id="titles02"
                       ref={this.timelineElemsObj.titles02}>
                    <Link to={urls.pl.gadgets}>
                      <div>GADŻETY</div>
                    </Link>
                    <Link to={urls.pl.papers}>
                      <div>ULOTKI<br/>WIZYTÓWKI</div>
                    </Link>
                    <Link to={urls.pl.outside}>
                      <div>REKLAMA<br/>ZEWNĘTRZNA</div>
                    </Link>
                  </div>

                  <div className={[fpStyles.titles, fpStyles.titles03, fpStyles.normalStage].join(" ")} id="titles03"
                       ref={this.timelineElemsObj.titles03}>
                    <Link to={urls.pl.marketing}>
                      <div>KAMPANIE<br/>REKLAMOWE</div>
                    </Link>
                    <Link to={urls.pl.websites}>
                      <div>STRONY WWW</div>
                    </Link>
                    <Link to={urls.pl.socialmedia}>
                      <div>OPIEKA<br/>SOCIAL MEDIA</div>
                    </Link>
                  </div>

                  <div className={[fpStyles.titles, fpStyles.titles04, fpStyles.normalStage].join(" ")} id="titles04"
                       ref={this.timelineElemsObj.titles04}>
                    <Link to={urls.pl.wideo}>
                      <div>WIDEO</div>
                    </Link>
                    <Link to={urls.pl.wrapping}>
                      <div>OZNAKOWANIE<br/>POJAZDÓW</div>
                    </Link>
                    <Link to={urls.pl.printing}>
                      <div>WYDRUKI<br/>WIELKOFORMATOWE</div>
                    </Link>
                  </div>

                  <div className={[fpStyles.titles, fpStyles.titles05, fpStyles.normalStage].join(" ")} id="titles05"
                       ref={this.timelineElemsObj.titles05}>
                    <Link to={urls.pl.it}>
                      <div>ZAAWANSOWANE<br/>STRONY WWW</div>
                    </Link>
                    <Link to={urls.pl.it}>
                      <div>SYSTEMY FIRMOWE</div>
                    </Link>
                    <Link to={urls.pl.it}>
                      <div>APLIKACJE MOBILNE</div>
                    </Link>
                  </div>


                  <div className={[fpStyles.dot, fpStyles.dot01].join(" ")}>
                    <div/>
                  </div>

                  <div className={[fpStyles.dot, fpStyles.dot02].join(" ")}>
                    <div/>
                  </div>

                  <div className={[fpStyles.dot, fpStyles.dot03].join(" ")}>
                    <div/>
                  </div>

                  <div className={[fpStyles.dot, fpStyles.dot04].join(" ")}>
                    <div/>
                  </div>

                  <div className={[fpStyles.dot, fpStyles.dot05].join(" ")}>
                    <div/>
                  </div>


                </div>
              </div>
            </div>

            <div ref={this.idiotInfo} className={fpStyles.idiot_info}>
              <div className={fpStyles.idiot_info_content}>
                <img src={iia_left}/>
                <div>Rowiń z nami swoją firmę</div>
                <img src={iia_right}/>
              </div>
            </div>
          </div>

          <Footer style={fpFooterStyles} onLandingPage={true}/>
        </div>
      </div>
    )
  }
}

export default
connect(state => ({
  scrollPos: state.scrollPos,
  windowW: state.windowW,
  windowH: state.windowH,
  menuH: state.menuH,
}), null)(NewIndex)