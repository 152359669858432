import React from "react"
import ReactVivus from "react-vivus"
import { Link } from "gatsby"

import divisions from "src/content/divisions"

import newMobileStyles from "src/css/fp-mobile-new.module.css"


export const BigBlock = ({ children }) => (
  <div className={[newMobileStyles.big_block, newMobileStyles.block].join(" ")}>
    {children}
  </div>
)

export const BigBlockContent = ({ division, icon_w = 55 }) => (
  <BigBlock>
    <Link to={division.link}>
      {/*<ReactVivus*/}
      {/*  id={division.id + "-icon"}*/}
      {/*  option={{*/}
      {/*    file: division.icon,*/}
      {/*    type: "delayed",*/}
      {/*    animTimingFunction: "EASE",*/}
      {/*    duration: 50,*/}
      {/*  }}*/}
      {/*  className={newMobileStyles.icon_mobile_division}*/}
      {/*  style={{*/}
      {/*    "width": icon_w + "%",*/}
      {/*  }}*/}
      {/*/>*/}
      <div className={newMobileStyles.label}>
        {division.title.pl.split("\n").map(title => {
          return <div>{title}</div>
        })}
      </div>
    </Link>
  </BigBlock>
)

export const SmallBlock = ({ children }) => (
  <div className={[newMobileStyles.small_block, newMobileStyles.block].join(" ")}>
    {children}
  </div>
)

export const SmallBlockContent = ({ division, paddingWidth = undefined }) => {
  let style = {}
  if (paddingWidth !== undefined) {
    style = {
      paddingLeft: paddingWidth + "%",
      paddingRight: paddingWidth + "%",
    }
  }
  return (
    <SmallBlock>
      <Link to={division.link}>
        <div className={newMobileStyles.icon_bg}>
          {/*<ReactVivus*/}
          {/*  id={division.id + "-icon"}*/}
          {/*  option={{*/}
          {/*    file: division.icon,*/}
          {/*    type: "delayed",*/}
          {/*    animTimingFunction: "EASE",*/}
          {/*    duration: 50,*/}
          {/*  }}*/}
          {/*  className={newMobileStyles.icon_mobile_division}*/}
          {/*  style={style}*/}
          {/*/>*/}
        </div>
        <div className={newMobileStyles.label}>{division.title.pl}</div>
      </Link>
    </SmallBlock>
  )
}

export const BlocksWrapper = ({ children, light = false }) => {
  let classes = [newMobileStyles.blocks_wrapper]
  if (light) {
    classes.push(newMobileStyles.blocks_wrapper_light)
  }
  return (
    <div className={classes.join(" ")}>
      {children}
      <div className={newMobileStyles.left_bar}/>
    </div>
  )
}