import React from "react"
import { connect } from "react-redux"

import Layout from "src/components/layout"
import SEO from "src/components/seo"

import NewIndex from "src/components/index/frontpage"
import MobileIndex from "src/components/index/frontpage_mobile"

class IndexPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      mobile: false,
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Departament Reklamy - Strona główna" keywords={[`gatsby`, `application`, `react`]} lang="pl"/>
        {this.props.isMobile.isMobile ? (
          <MobileIndex/>
        ) : (
          <NewIndex/>
        )}
      </Layout>
    )
  }
}

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(IndexPage)
