import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactVivus from "react-vivus"
import ReactSVG from "react-svg"

import styles from "src/css/fp-new.module.css"

import fingerprint from "src/images/icons2/fingerprint.svg"


export default class Gradients extends React.Component {
  constructor(props) {
    super(props)

    this.gradsObj = {
      grad2_01: React.createRef(),
      grad2_02: React.createRef(),
      grad2_03: React.createRef(),
      grad2_04: React.createRef(),
      grad2_05: React.createRef(),
    }
  }

  render() {
    if (this.props.activeGradient !== undefined) {
      let activeGrad = this.props.activeGradient

      setTimeout(() => {
        if (this.props.activeGradient === activeGrad)
          Object.keys(this.gradsObj).map((key) => {
            let grad = this.gradsObj[key]
            if (grad.current != null) {
              grad = grad.current
              if (grad.id === "grad2_0" + activeGrad) {
                if (grad.classList.contains(styles.hiddenGrad)) {
                  grad.classList.remove(styles.hiddenGrad)
                  grad.classList.add(styles.activeGrad)
                }
              }
              else if (grad.classList.contains(styles.activeGrad)) {
                grad.classList.remove(styles.activeGrad)
                grad.classList.add(styles.hiddenGrad)
              }
            }
          })
      }, 500)
    } else {
      Object.keys(this.gradsObj).map((key) => {
        let grad = this.gradsObj[key]
        if (grad.current != null) {
          grad = grad.current
          if (grad.classList.contains(styles.activeGrad) && grad.id !== "grad2_01") {
            grad.classList.remove(styles.activeGrad)
            grad.classList.add(styles.hiddenGrad)
          }
        }
      })

      setTimeout(() => {
        if (this.props.activeGradient === undefined) {
          if (this.gradsObj.grad2_01.current != null) {
            let grad = this.gradsObj.grad2_01.current
            if (grad.classList.contains(styles.hiddenGrad)) {
              grad.classList.remove(styles.hiddenGrad)
              grad.classList.add(styles.activeGrad)
            }
          }
        }
      }, 500)
    }

    return (
      <div className={styles.grads}>
        <div className={styles.grad1}/>
        <div className={[styles.grad2, styles.grad2_stage1, styles.activeGrad].join(" ")} id="grad2_01"
             ref={this.gradsObj.grad2_01}/>
        <div className={[styles.grad2, styles.grad2_stage2, styles.hiddenGrad].join(" ")} id="grad2_02"
             ref={this.gradsObj.grad2_02}/>
        <div className={[styles.grad2, styles.grad2_stage3, styles.hiddenGrad].join(" ")} id="grad2_03"
             ref={this.gradsObj.grad2_03}/>
        <div className={[styles.grad2, styles.grad2_stage4, styles.hiddenGrad].join(" ")} id="grad2_04"
             ref={this.gradsObj.grad2_04}/>
        <div className={[styles.grad2, styles.grad2_stage5, styles.hiddenGrad].join(" ")} id="grad2_05"
             ref={this.gradsObj.grad2_05}/>
        {this.props.iconBg !== undefined ? (
          <div className={styles.grad_bg_icon}>
            {/*<ReactVivus*/}
            {/*  id="bgIcon2"*/}
            {/*  option={{*/}
            {/*    file: this.props.iconBg,*/}
            {/*    type: "delayed",*/}
            {/*    animTimingFunction: "EASE",*/}
            {/*    duration: 100,*/}
            {/*    start: "autostart"*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
        ) : null}
      </div>
    )
  }
}

Gradients.propTypes = {
  activeGradient: PropTypes.number,
  iconBg: PropTypes.string
}

Gradients.defaultProps = {
  activeGradient: undefined,
  iconBg: undefined,
}
