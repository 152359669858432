import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "gatsby"
import ReactVivus from "react-vivus"

import GradientsNew from "src/components/gradients-new"
import MobileMenu from "src/components/mobile_menu"
import { BlocksWrapper, BigBlockContent, SmallBlockContent } from "src/components/index/frontpage_mobile_blocks"

import divisions from "src/content/divisions"
import mIndexStyles from "src/css/mobile_index.module.css"
import fpStyles from "src/css/fp-new.module.css"

import blueLogo from "src/images/logo-blueBorder.png"
import hand from "src/images/icons_sizes/our_services.svg"

import newMobileStyles from "src/css/fp-mobile-new.module.css"

import urls from "../../content/urls"

class MobileIndex extends React.Component {
  constructor(props) {
    super(props)

    this.topBar = React.createRef()
    this.bottomBar = React.createRef()

    this.timelineElemsObj = {
      line01: React.createRef(),
      line02: React.createRef(),
      line03: React.createRef(),
      line04: React.createRef(),
      line05: React.createRef(),
      line06: React.createRef(),
      chata: React.createRef(),
      oscar: React.createRef(),
      oscarImg: React.createRef(),
      stage01: React.createRef(),
      stage02: React.createRef(),
      stage03: React.createRef(),
      stage04: React.createRef(),
      stage05: React.createRef(),
      titles01: React.createRef(),
      titles02: React.createRef(),
      titles03: React.createRef(),
      titles04: React.createRef(),
      titles05: React.createRef(),
    }
  }

  render() {
    let oscar_offset = 0
    if (this.timelineElemsObj.oscarImg.current != null) {
      oscar_offset = this.timelineElemsObj.oscarImg.current.clientHeight * 0.40 - 30
    }

    return (
      <>
        <GradientsNew activeGradient={1}/>
        <MobileMenu/>
        <div className={mIndexStyles.fp_body}>
          <div className={mIndexStyles.fp_logo_wrapper}>
            <div className={mIndexStyles.fp_logo}>
              <img src={blueLogo}/>
            </div>
          </div>

          <div className={newMobileStyles.body_wrapper}>
            <div className={[newMobileStyles.blocks_wrapper, newMobileStyles.blocks_wrapper_light, newMobileStyles.right].join(" ")}>
              <div className={newMobileStyles.left_bar}/>
              <div className={newMobileStyles.services_wrapper}>
                <div className={newMobileStyles.label}>
                  Jak możemy<br/>Ci pomóć?
                </div>
                <div className={newMobileStyles.icon_bg}>
                  {/*<ReactVivus*/}
                  {/*  id={"services_hand"}*/}
                  {/*  option={{*/}
                  {/*    file: hand,*/}
                  {/*    type: "delayed",*/}
                  {/*    animTimingFunction: "EASE",*/}
                  {/*    duration: 100,*/}
                  {/*  }}/>*/}
                </div>
              </div>
            </div>
            <BlocksWrapper>
              <BigBlockContent division={divisions.video}/>
              <BigBlockContent division={divisions.wrapping}/>
              <BigBlockContent division={divisions.website_mobile}/>
            </BlocksWrapper>
            <BlocksWrapper>
              <BigBlockContent division={divisions.it} icon_w={65}/>
            </BlocksWrapper>
            <BlocksWrapper light={true}>
              <SmallBlockContent division={divisions.socialmedia}/>
              <SmallBlockContent division={divisions.gadgets}/>
              <SmallBlockContent division={divisions.branding} paddingWidth={20}/>
            </BlocksWrapper>
            <BlocksWrapper light={true}>
              <SmallBlockContent division={divisions.papers} paddingWidth={15}/>
              <SmallBlockContent division={divisions.outdoor}/>
              <SmallBlockContent division={divisions.marketing} paddingWidth={17}/>
            </BlocksWrapper>
          </div>
        </div>
      </>
    )
  }
}

export default connect(state => ({
  scrollPos: state.scrollPos,
}), null)(MobileIndex)